<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      max-width="1200px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ editMode ? `${$t('Update Purchase')} ${formData.invoice_no}` : $t('Add New Purchase') }}
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(confirmSubmit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col class="px-1">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Supplier')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.supplier_id"
                      :label="`* ${$t('Supplier')}`"
                      :items="suppliersList"
                      :item-text="item => item.name"
                      :item-value="item => item.id"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col class="px-1">
                  <v-menu
                    ref="actionDate"
                    v-model="dateMenu"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formData.action_date"
                        :label="$t('Date')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="formData.action_date"
                      scrollable
                      no-title
                      @input="dateMenu = false"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="12"
                  md="6"
                  lg="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Note')"
                  >
                    <v-text-field
                      v-model="formData.note"
                      :label="$t('Note')"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                class="justify-space-between"
              >
                <v-col
                  class="px-1"
                  cols="12"
                  sm="5"
                  md="4"
                >
                  <app-item-search @selected="handleItemSelect($event)" />
                </v-col>

                <v-col
                  class="px-1 d-flex justify-space-between align-center"
                  cols="12"
                  sm="5"
                  md="4"
                >
                  <div class="text-subtitle-1">
                    {{ $t('Total Quantity') }}:
                    {{ $_format_number(formData.total_quantity) }}
                  </div>

                  <div class="text-subtitle-1 font-weight-bold">
                    {{ $t('Net Price') }}:
                    {{ $_format_number(formData.net_price) }}
                  </div>
                </v-col>

                <div
                  style="overflow-x: scroll"
                  class="mt-4"
                >
                  <v-simple-table style="min-width: 1160px;">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th style="width: 150px">
                            {{ $t('Code') }}
                          </th>
                          <th style="width: 200px">
                            {{ $t('Name') }}
                          </th>
                          <th>
                            {{ $t('Variant') }}
                          </th>
                          <th
                            style="width: 130px"
                            class="text-center"
                          >
                            {{ $t('Quantity') }}
                          </th>
                          <th
                            style="width: 150px"
                            class="text-center"
                          >
                            {{ $t('Purchase Price') }}
                          </th>
                          <th
                            style="width: 130px"
                            class="text-center"
                          >
                            {{ $t('Total Price') }}
                          </th>
                          <th
                            style="width: 130px"
                            class="text-center"
                          >
                            {{ $t('Sale Price') }}
                          </th>
                          <th
                            style="width: 150px"
                            class="text-center"
                          >
                            {{ $t('Wholesale Price') }}
                          </th>
                          <th
                            style="width: 80px"
                            class="text-center"
                          >
                            {{ $t('Actions') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(detail, index) in formData.details"
                          :key="index"
                        >
                          <td>{{ detail.item_code }}</td>

                          <td>{{ detail.item_name }}</td>

                          <td>
                            <div class="d-flex">
                              {{ detail.item_variant }}
                            </div>
                          </td>

                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Quantity') + (index + 1)"
                              rules="required|min_value:0.1"
                            >
                              <v-currency-field
                                v-model="detail.quantity"
                                :label="`* ${$t('Quantity')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Purchase Price') + (index + 1)"
                              rules="required|min_value:0.1"
                            >
                              <v-currency-field
                                v-model="detail.purchase_price"
                                :label="`* ${$t('Purchase Price')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td class="text-center">
                            {{ $_format_number(detail.quantity * detail.purchase_price) }}
                          </td>

                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Sale Price') + (index + 1)"
                              rules="required|min_value:0.1"
                            >
                              <v-currency-field
                                v-model="detail.sale_price"
                                :label="`* ${$t('Sale Price')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Wholesale Price') + (index + 1)"
                            >
                              <v-currency-field
                                v-model="detail.wholesale_price"
                                :label="`* ${$t('Wholesale Price')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td class="text-center">
                            <v-btn
                              v-if="!detail.id"
                              color="error"
                              icon
                              small
                              class="rounded"
                              depressed
                              outlined
                              @click="removeDetail(index)"
                            >
                              <v-icon class="mdil-rotate-45">
                                mdil-plus
                              </v-icon>
                            </v-btn>

                            <v-btn
                              v-if="detail.id"
                              color="error"
                              icon
                              small
                              class="rounded"
                              depressed
                              outlined
                              @click="confirmDeleteDetail(detail, index)"
                            >
                              <v-icon small>
                                mdil-delete
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <app-submit-button :edit-mode="editMode" />
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <confirmation
      :confirm-dialog="confirmDialog"
      :message="confirmMsg"
      :confirm-btn-text="confirmBtnText"
      :confirm-btn-icon="confirmBtnIcon"
      :confirm-btn-color="confirmBtnColor"
      :title-color="titleColor"
      @cancel="confirmDialog = false"
      @confirm="decide()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  data() {
    return {
      dateMenu: false,
      detailIndex: -1,
      detailId: 0,
      confirmDialog: false,
      titleColor: '',
      confirmMsg: '',
      confirmType: '',
      confirmBtnText: '',
      confirmBtnIcon: '',
      confirmBtnColor: '',
    }
  },

  computed: {
    ...mapGetters(['suppliersList'])
  },

  watch: {
    formData: {
      immediate: false,
      deep: true,
      handler() {
        this.calculate()
      }
    }
  },

  methods: {
    decide() {
      if (this.confirmType == 'delete') {
        this.deleteDetail()
      } else if (this.confirmType == 'add') {
        this.submit()
      }
    },

    handleItemSelect(item) {
      this.formData.details.push({
        item_code: item.code,
        item_name: item.name,
        item_variant: item.variant,
        item_id: item.id,
        stock_quantity: item.quantity,
        purchase_price: item.purchase_price,
        sale_price: item.sale_price,
        wholesale_price: item.wholesale_price,
      })
    },

    removeDetail(index) {
      this.formData.details.splice(index, 1)
    },

    confirmDeleteDetail(detail, index) {
      this.detailId = detail.id
      this.detailIndex = index
      this.confirmType = 'delete'
      this.titleColor = 'error--text'
      this.confirmBtnText = 'Delete'
      this.confirmBtnIcon = 'mdil-delete'
      this.confirmBtnColor = 'error'
      this.confirmMsg = `Are you sure you want to delete this item [${detail.item_code}] ${detail.item_name}`
      this.confirmDialog = true
    },

    deleteDetail() {
      axios.delete(`purchase-detail/${this.detailId}/`).then(() => {
        this.$_notify('Deleted successfully')
        this.$emit('update-table-data')
        this.formData.details.splice(this.detailIndex, 1)
      }).finally(() => this.confirmDialog = false)
    },

    calculate() {
      let netPrice = 0
      let totalQuantity = 0

      let qty = 0
      let pPrice = 0
      this.formData.details.forEach(detail => {
        qty = detail.quantity ?? 0
        pPrice = detail.purchase_price ?? 0

        netPrice += qty * pPrice
        totalQuantity += qty
      })

      Vue.set(this.formData, 'net_price', netPrice)
      Vue.set(this.formData, 'total_quantity', totalQuantity)
    },

    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    confirmSubmit() {
      this.confirmType = 'add'
      this.titleColor = 'primary--text'
      this.confirmBtnText = 'Yes'
      this.confirmBtnIcon = 'mdil-check'
      this.confirmBtnColor = 'primary'
      this.confirmMsg = 'Are you sure you want to save this purchase ?'
      this.confirmDialog = true
    },

    submit() {
      if (this.editMode) {
        axios.put(`purchase/${this.formData.id}/`, this.formData).then(() => {
          this.$_notify('Updated successfully')
          this.closeDialog()
          this.$emit('update-table-data')
        }).finally(() => this.confirmDialog = false)
      } else {
        axios.post('purchase/', this.formData).then(() => {
          this.$_notify('Added successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        }).finally(() => this.confirmDialog = false)
      }
    }
  },
}
</script>
<style lang="scss">
</style>